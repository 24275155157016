export default [
  // {
  //   key: 'checkbox',
  //   label: '',
  //   sortable: false,
  //   class: 'text-center align-middle text-uppercase',
  //   show: true,
  //   type: 'checkbox',
  // },
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'number',
    label: 'Number',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'account_id',
    label: 'Account',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },

  {
    key: 'credits',
    label: 'Credits',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },

  {
    key: 'debits',
    label: 'Debits',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'client_id',
    label: 'Business Entity',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'description',
    label: 'Description',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    label: 'Create At',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
]
