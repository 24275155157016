
import JournalEntry from '@/models/JournalEntry'
import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import JournalEntryItem from '@/models/JournalEntryItem'
import IconAction from '@/components/IconAction/IconAction.vue'
import DataTable from '@/components/DataTable/index.vue'
import AccountPicker from '@/components/AccountPicker/AccountPicker.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import { integerMask, currencyMask } from '@/models/interface/Masks'
import WebMessage from '@/models/WebMessage'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import items_fields from './entry-items-fields'

@Component({
  components: {
    Widget,
    FormWizard,
    TabContent,
    FooterNav,
    FormInput,
    DatePicker,
    IconAction,
    DataTable,
    AccountPicker,
    CompanyPicker,
    SelectPicker,
  },
})
export default class JournalEntryEdit extends Vue {
  @Ref() readonly formWizard!: any

  @Ref() readonly entriesItemsTable!: any

  public journal_entry: JournalEntry = new JournalEntry()

  public loading: boolean = false

  public is_editing: boolean = false

  public busy: boolean = false

  public step: number = 0

  public fields: any = []

  public account_types: any = [
    { name: 'Discounts', value: 'discounts' },
    { name: 'Expenses', value: 'expenses' },
  ]

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get masks() {
    return {
      integerMask,
      currencyMask,
    }
  }

  public mounted() {
    this.fields = items_fields
    if (this.$route.params.id) {
      this.loading = true
      this.journal_entry.find(this.$route.params.id).then(journal => {
        this.journal_entry = journal
        this.loading = false
        this.formWizard.activateAll()
      })
    } else if (!this.journal_entry.items.length) {
      let item = new JournalEntryItem()
      item.number = 1
      this.journal_entry.items.push(item)
    }
  }

  public refresh(timeout: any = 0) {
    setTimeout(() => {
      this.entriesItemsTable.refresh()
    }, timeout)
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public validateStep() {
    return true
  }

  public async onSubmit() {
    // Disabled for now (Sometimes a journal can be debit only)
    // if (!this.journal_entry.can_save) {
    //   WebMessage.warning('Debits and Credits must be equal')
    //   return
    // }
    this.loading = true
    this.journal_entry
      .save()
      .then((res: any) => {
        this.busy = false
        this.loading = false
        if (res.status === 200) this.$router.push({ name: 'journal-entries' })
      })
      .catch((err: any) => {
        this.busy = false
        this.loading = false
      })
  }

  public addItem() {
    let item = new JournalEntryItem()
    item.number = this.journal_entry.items.length + 1
    this.journal_entry.items.push(item)
    this.refresh(500)
  }

  public removeItem(row: any) {
    this.journal_entry.items.splice(row.index, 1)
    this.journal_entry.items.forEach((item: any, index: number) => {
      item.number = index + 1
    })
    this.refresh(500)
  }

  public cloneItem(row: any) {
    let item = new JournalEntryItem()
    item = Object.assign(item, row.item)
    item.number = this.journal_entry.items.length + 1
    this.journal_entry.items.push(item)
    this.refresh(500)
  }
}
