var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:{ 'overflow-auto': _vm.noOverflowAuto === false }},[_c('b-table',{ref:"dataTable",class:_vm.$attrs['table-class'] ?? '',attrs:{"id":_vm.uuid,"items":_vm.rows,"fields":_vm.fields,"sort-by":_vm.sort_by,"sort-desc":_vm.sort_desc,"striped":"","filter":_vm.query,"show-empty":"","per-page":_vm.recordsPerPage,"current-page":_vm.page,"busy":_vm.loading,"foot-clone":_vm.footClone !== false,"no-footer-sorting":_vm.noFooterSorting !== false,"sticky-header":_vm.stickyHeader,"bordered":_vm.$attrs['bordered'] ?? false},on:{"update:sortBy":function($event){_vm.sort_by=$event},"update:sort-by":function($event){_vm.sort_by=$event},"update:sortDesc":function($event){_vm.sort_desc=$event},"update:sort-desc":function($event){_vm.sort_desc=$event},"head-clicked":(e) => _vm.$emit('head-clicked', e)},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}}),{key:"head()",fn:function(scope){return [(scope.field.type === 'checkbox')?_c('div',{staticClass:"text-nowrap"},[_c('b-form-checkbox',{attrs:{"id":"select-all","checked":_vm.allSelected,"indeterminate":_vm.indeterminate},on:{"change":_vm.toggleSelectAll}})],1):(
            _vm.fieldFilters === false ||
              scope.field.filter === false ||
              ['action', 'action_list'].includes(scope.field.type)
          )?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(scope.label)+" ")]):(scope.field.filter_type === 'picker')?_c('div',[_c('b-form-select',{attrs:{"text-field":"name","name":"date","type":"date","placeholder":`${scope.label}`,"options":scope.field.options,"clearable":true,"empty_value":true},on:{"change":() => {
                if (_vm.filterSetsPage) {
                  _vm.page = 1
                }
                _vm.refresh()
              }},model:{value:(_vm.localFieldFilters[scope.field.key]),callback:function ($$v) {_vm.$set(_vm.localFieldFilters, scope.field.key, $$v)},expression:"localFieldFilters[scope.field.key]"}})],1):(scope.field.filter_type === 'date')?_c('div',{staticClass:"pt-3"},[_c('DatePicker',{attrs:{"vertical":"","name":"date","type":"date","placeholder":`${scope.label}`,"clearable":true,"empty_value":true},on:{"clear":_vm.refresh,"confirm":() => {
                if (_vm.filterSetsPage) {
                  _vm.page = 1
                }
                _vm.refresh()
              }},model:{value:(_vm.localFieldFilters[scope.field.key]),callback:function ($$v) {_vm.$set(_vm.localFieldFilters, scope.field.key, $$v)},expression:"localFieldFilters[scope.field.key]"}})],1):_c('div',[_c('b-form-input',{attrs:{"placeholder":`${scope.label}`,"disabled":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => {
                if (_vm.filterSetsPage) {
                  _vm.page = 1
                }
                _vm.refresh()
              }).apply(null, arguments)},"input":_vm.onFilterChange},model:{value:(_vm.localFieldFilters[scope.field.key]),callback:function ($$v) {_vm.$set(_vm.localFieldFilters, scope.field.key, $$v)},expression:"localFieldFilters[scope.field.key]"}})],1)]}},{key:"cell()",fn:function(data){return [(data.field.type === 'date')?_c('span',[(data.value)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(data.value,'MMMM, Do YYYY')))]):_c('span',[_vm._v("-")])]):(data.field.type === 'month')?_c('span',[(data.value)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(data.value,'MMMM YYYY')))]):_c('span',[_vm._v("-")])]):(data.field.type === 'datetime')?_c('span',[(data.value)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(data.value,'MMMM, Do YYYY, h:mm:ss a')))]):_c('span',[_vm._v("-")])]):(data.field.type === 'time')?_c('span',[(data.value)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(data.value,'h:mm:ss a')))]):_c('span',[_vm._v("-")])]):(data.field.type === 'boolean')?_c('span',[(data.value)?_c('span',[_vm._v("Yes")]):_c('span',[_vm._v("No")])]):(data.field.type === 'capitalize')?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(data.value))+" ")]):(data.field.type === 'capitalize_replace')?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(data.value.replaceAll('_', ' ')))+" ")]):(data.field.type === 'uppercase')?_c('span',[_vm._v(" "+_vm._s(_vm._f("uppercase")(data.value))+" ")]):(data.field.type === 'currency')?_c('span',[(typeof data.value === 'string' && data.value.includes('|'))?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(data.value.split('|')[0]))+" "),_c('sup',{class:{
                'text-success': data.value.split('|')[0] > 0,
                'text-danger': data.value.split('|')[0] < 0,
              }},[_vm._v(" "+_vm._s(data.value.split('|')[0] > 0 ? '▴' : '▾')+" "+_vm._s(_vm._f("numeral")(data.value.split('|')[1],'0%'))+" ")])]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(data.value)))])]):(data.field.type === 'currency_abbreviate')?_c('span',[_vm._v(" $ "+_vm._s(_vm._f("abbreviate")(data.value))+" ")]):(data.field.type === 'separator' || data.field.type === 'numeral')?_c('span',[_vm._v(" "+_vm._s(_vm._f("separator")(data.value))+" ")]):(data.field.type === 'abbreviate')?_c('span',[_vm._v(" "+_vm._s(_vm._f("abbreviate")(data.value))+" ")]):(data.field.type === 'percentage')?_c('span',[_vm._v(" "+_vm._s(_vm._f("percentage")(data.value))+" ")]):(data.field.type === 'json')?_c('span',[(typeof data.value === 'string')?_c('span',[_vm._v(" "+_vm._s(data.value)+" ")]):_c('span',_vm._l((data.value),function(object,ok){return _c('div',{key:ok,staticClass:"mb-3"},[_c('b',[_vm._v(_vm._s(_vm._f("capitalize")(ok.replaceAll('_', ' '))))]),_c('br'),_c('hr',{staticClass:"mt-1"}),_vm._l((object),function(property,pk){return _c('span',{key:pk,staticClass:"ml-2"},[_c('b',[_vm._v(_vm._s(_vm._f("capitalize")(pk.replaceAll('_', ' ')))+":")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(property))]),_c('br')])})],2)}),0)]):(data.field.type === 'checkbox')?_c('span',[_c('b-form-checkbox',{attrs:{"value":data.item.id,"name":_vm.uuid},model:{value:(_vm.local_checked),callback:function ($$v) {_vm.local_checked=$$v},expression:"local_checked"}})],1):(data.field.type === 'badge')?_c('span',[_c('b-badge',{attrs:{"variant":data.field.color(data.item)}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(data.value))+" ")])],1):(data.field.type === 'badge_uppercase')?_c('span',[_c('b-badge',{attrs:{"variant":data.field.color(data.item)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(data.value))+" ")])],1):(data.field.type === 'object')?_c('span',[(
              data.item[data.field.object.key] &&
                data.item[data.field.object.key][data.field.object.property]
            )?_c('span',[_vm._v(" "+_vm._s(data.item[data.field.object.key][data.field.object.property])+" ")]):_c('span',[_vm._v("N/A")])]):(data.field.type === 'action')?_c('span',_vm._l((data.field.actions.filter((a) => !a.show || a.show(data.item))),function(action){return _c('IconAction',{key:action.event,attrs:{"title":action.title,"icon":action.icon,"custom-icon":action.customIcon ? action.customIcon : false},on:{"click":() => {
                if (action.event === 'details') {
                  data.toggleDetails()

                  if (action.action) action.action(data.item)
                } else {
                  _vm.triggerAction(action.event, data.item)
                }
              }}})}),1):(data.field.type === 'action_list')?_c('span',[_vm._l((data.field.actions.filter(
              (a) =>
                ((typeof a.top_level === 'function' && a.top_level(data.item)) ||
                  (typeof a.top_level !== 'function' && a.top_level === true)) &&
                ((typeof a.show === 'function' && a.show(data.item)) ||
                  a.show === undefined ||
                  a.show === true),
            )),function(action){return _c('IconAction',{key:action.event,attrs:{"title":action.title,"icon":action.icon,"custom-icon":action.customIcon ? action.customIcon : false},on:{"click":() => {
                if (action.event === 'details') {
                  data.toggleDetails()

                  if (action.action) action.action(data.item)
                } else {
                  _vm.triggerAction(action.event, data.item)
                }
              }}})}),_c('b-dropdown',{staticClass:"text-decoration-none",attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none px-2","no-caret":"","right":"","boundary":"window"}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"la la-ellipsis-v la-lg text-primary"})]),_vm._l((data.field.actions.filter(
                (a) =>
                  ((typeof a.top_level === 'function' && !a.top_level(data.item)) ||
                    (typeof a.top_level !== 'function' && a.top_level !== true)) &&
                  ((typeof a.show === 'function' && a.show(data.item)) ||
                    a.show === undefined ||
                    a.show === true),
              )),function(action){return _c('b-dropdown-item',{key:action.event,on:{"click":function($event){action.event === 'details'
                  ? data.toggleDetails()
                  : _vm.triggerAction(action.event, data.item)}}},[(action.customIcon)?_c('CustomIcon',{staticClass:"mr-1",attrs:{"icon":action.customIcon,"color":"secondary"}}):(action.icon)?_c('i',{class:`la la-${action.icon} la-small mr-2`}):_vm._e(),_vm._v(" "+_vm._s(action.title)+" ")],1)})],2)],2):_c('span',[_vm._v(_vm._s(data.value))])]}}],null,true)})],1),(!_vm.noPagination)?_c('b-row',[_c('b-col',{staticClass:"mt-4 pt-1"},[_c('b-pagination',{attrs:{"total-rows":_vm.records,"per-page":_vm.page_size,"aria-controls":_vm.uuid},on:{"change":_vm.resetSelection},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('b-col',{staticClass:"text-right",attrs:{"sm":"12","md":"6","lg":"5","xl":"3"}},[_c('SelectPicker',{attrs:{"id":"report","vertical":"","options":_vm.pagination_options,"placeholder":"Select one","track-by":"name","label":_vm.records_text,"name":"report"},model:{value:(_vm.page_size),callback:function ($$v) {_vm.page_size=$$v},expression:"page_size"}})],1)],1):_vm._e(),_c('div',{ref:"container"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }